import React, { useState } from "react"
import Layout from "../../chunks/layout"
import { Helmet } from "react-helmet"
import Main from "../../components/main"
import Pricing from "../../components/pricing"
import GuaranteeSection from "../../components/guaranteesection"
import Features from "../../components/features"
import Testimonials from "../../components/testimonials"
import Faqs from "../../components/faqs"
import HowItWorks from "../../components/howitworks"

const Instagram = props => {
  const [productName, setProductName] = useState(props.productName)
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Main
        viewName="instagram"
        title="Accelerate your growth on Instagram"
        description="Instagram followers and likes delivered in minutes. Best way to get more engagement and success."
      />
      <div className="w-full bg-gray">
        <div className="background-gray-wave-separator-rotated"></div>
      </div>
      <div className="section-container bg-gray">
        <div className="section-content">
          <div className="flex justify-center items-center">
            <button
              className={`pricing-menu-button ${
                productName === "followers" &&
                `pricing-menu-button-selected color-instagram shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("followers")
              }}
              onKeyDown={() => {
                setProductName("followers")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-user text-3xl"></i>
              </div>
              Followers
            </button>
            <button
              className={`pricing-menu-button ${
                productName === "likes" &&
                `pricing-menu-button-selected color-instagram shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("likes")
              }}
              onKeyDown={() => {
                setProductName("likes")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-heart text-3xl"></i>
              </div>
              Likes
            </button>
            <button
              className={`pricing-menu-button ${
                productName === "views" &&
                `pricing-menu-button-selected color-instagram shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("views")
              }}
              onKeyDown={() => {
                setProductName("views")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-show text-3xl"></i>
              </div>
              Views
            </button>
          </div>
        </div>
        <Pricing
          viewName="instagram"
          categoryName="instagram"
          productName={productName}
        />
      </div>
      <GuaranteeSection viewName="instagram" />
      <div className="section-container background-section-gray">
        <Features viewName="instagram" categoryName="instagram" />
      </div>
      <HowItWorks viewName="instagram" categoryName="instagram" />
      {/*<div className="w-full flex flex-col justify-center background-gradient-instagram">
        <div
          className={`background-${
            props.viewName !== "home" ? "gray" : "white"
          }-wave-separator-rotated`}
        ></div>
        <div className="container mx-auto text-center p-10">
          <div className="section-title color-white">
            Buy quality services for <b>Instagram</b> with Boostik
          </div>
        </div>
        <div className="container mx-auto p-10 text-white">
          We give you that initial push you need to get your account off the
          ground. Followers are key players of your Instagram success. Buy likes
          and followers from Boostik, and watch now as your Instagram profile
          gains more exposure and visibility.
        </div>
        <div className="background-gray-wave-separator"></div>
      </div>*/}
      <div className="background-section-gray">
        <div className="container mx-auto text-center">
          <div className={`section-title color-instagram mt-10`}>
            Some Reviews and Customer Feedback
          </div>
          <div>
            <Testimonials
              categoryName="instagram"
              testimonialsList={[
                {
                  name: "Christian Sanchez",
                  review: 4,
                  comment:
                    "“Best service for IG followers! I definitely will keep using it”.",
                  userImage: "user1",
                },
                {
                  name: "Nadia Pérez",
                  review: 5,
                  comment: "“It worked, thanks a lot!”.",
                  userImage: "user2",
                },
                {
                  name: "Mina Sullivan",
                  review: 5,
                  comment:
                    "“I like the way the followers come in less than 30 min”.",
                  userImage: "user3",
                },
                {
                  name: "Dennis Smith",
                  review: 4.5,
                  comment: "“Quick and easy”.",
                  userImage: "user5",
                },
                {
                  name: "Olivia Garcia",
                  review: 4,
                  comment:
                    "“Works in a couple of hours, nice customer support also”.",
                  userImage: "user7",
                },
                {
                  name: "Dan Thomas",
                  review: 4.5,
                  comment:
                    "“I received my followers as promised, satisfied with the service guys”.",
                  userImage: "user6",
                },
                {
                  name: "Kristen Davis",
                  review: 4,
                  comment:
                    "“Great, I  will definitely come back to you in the future.”",
                  userImage: "user9",
                },
                {
                  name: "Hannah Moore",
                  review: 5,
                  comment: "“It works on time”.",
                  userImage: "user10",
                },
                {
                  name: "Dom Thompson",
                  review: 4,
                  comment:
                    "“Super fast delivery! Got 100 followers within 5 or less minutes”.",
                  userImage: "user11",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Faqs
        listOfFaqs={[
          {
            title: "IS THIS SAFE FOR MY ACCOUNT?",
            description:
              "Yes, it’s 100% safe to use Boostik. All of our social media marketing services comply fully with the terms, conditions and guidelines of whichever platform we’re dealing with. There is absolutely no chance whatsoever that your account will be penalized in any way. We constantly monitor all of the major social media players for updates and adapt our services accordingly. This means that you will always be one step ahead and your account will never be in risk.",
          },
          {
            title: "HOW FAST WILL I RECEIVE THE SERVICES?",
            description:
              "That depends on which service you are ordering. Because each service is different, some packages, like our Instagram likes, for example, are super fast (you will start to see them in just a few minutes). Other services may take an hour, a couple of hours, or even more. The longest you will ever have to wait for any service will be 24 hours. Please note that delivery times are estimated and may vary during busier periods. In case you need more information about our delivery times, just send us an email. We’ll be glad to resolve your doubts. Whichever package you choose to order, you can rest assured that you will get it in the fastest possible time.",
          },
          {
            title: "DO YOU NEED MY PASSWORD?",
            description:
              "It is not necessary to have your password in order to deliver a high-quality service. All we need is a link to the content you want to promote to get started. We do NOT require and will never ask for your password or account access. All we need is your Social Media URL/username and your email so that we can contact you regarding your order process. By doing so, we eliminate the possibility of your Social Media profile being breached by any third parties.",
          },
          {
            title: "WHICH PACKAGE IS RIGHT FOR ME?",
            description:
              "We have assigned a dedicated team of experts to constantly analyze Instagram. This makes it possible for us to always provide the 'Best Value' option for the most unbeatable price on the Internet. As for the right package for you, this will depend on your requirements. As a general rule of thumb, we suggest that your number of followers is naturally proportional to your number of likes. Remember that we are at your disposal in case you need further help.",
          },
          {
            title: "WHAT PAYMENT METHODS DO YOU ACCEPT?",
            description:
              "At the moment we accept payments through credit and debit cards.",
          },
          {
            title: "CAN I GET A REFUND?",
            description:
              "Your order is eligible for refund if it has not been completed yet. If we fail to deliver your order, you can request for a full refund. Please note different packages have different delivery periods. ",
          },
          {
            title: "CAN I CANCEL MY ORDER?",
            description:
              "Once an order has been placed it is not always possible to cancel it. Many of our services have very fast delivery speeds, therefore making it impossible to cancel the order before it’s delivered. In the event that you need to cancel an order please contact our Customer Support Service and we will do our best to stop it as soon as possible. We do not issue refunds for services that have been delivered.",
          },
          {
            title: "MORE QUESTIONS?",
            description:
              "If you cannot find your answer here, you can send us an email. Our Customer Support Team will be happy to help you!",
          },
        ]}
        viewName="instagram"
      />
    </Layout>
  )
}

export default Instagram
